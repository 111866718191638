<template>
  <div>
    <center>
      <div class="mb-4">
        <img
          style="cursor: pointer"
          src="https://d3oia8etllorh5.cloudfront.net/us-east-1_dM0EI6uHe/ALL/20211208193832/assets/images/image.jpg"
          @click="login"
        />
      </div>
      <v-alert icon="mdi-login" prominent text type="info">
        <v-row align="center" no-gutters>
          <v-col class="grow">
            <h2 class="font-weight-light">Initializing login</h2>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="shrink"> &nbsp; </v-col>
        </v-row>
      </v-alert>
    </center>
  </div>
</template>

<script>
import { initLogin } from "@/clients/auth";

export default {
  name: "InitLogin",
  created() {
    initLogin();
  },
  methods: {
    login: function () {
      initLogin();
    },
  },
};
</script>
